<template>
  <div class="mobile-container">
    <div class="page-header">
      <div class="back-item img-cover" @click="$router.go(-1)"><img src="../../assets/images/back-icon-black.png" /></div>
      <div class="page-type-item">
        <div class="item" :class="pageType == 1 ? 'active' : ''" @click="pageType = 1">订单</div>
        <div class="item" :class="pageType == 2 ? 'active' : ''" @click="pageType = 2">待执行</div>
      </div>
      <div class="search-item">
        <div class="icon img-cover"><img src="../../assets/images/search-icon.png" /></div>
        <div class="input"><input v-model="searchKey" type="text" placeholder="输入要搜索的内容" @keyup.enter="handleSearch"></div>
        <div class="btn" @click="handleSearch">搜索</div>
      </div>
      <div v-if="pageType == 1" class="status-item">
        <div class="item" :class="status === 0 ? 'active' : ''" @click="status = 0">全部</div>
        <div class="item" :class="status === 8 ? 'active' : ''" @click="status = 8">处理中</div>
        <div class="item" :class="status === 2 ? 'active' : ''" @click="status = 2">处理中</div>
        <div class="item" :class="status === 4 ? 'active' : ''" @click="status = 4">已完成</div>
        <div class="item" :class="status === 7 ? 'active' : ''" @click="status = 7">已退款</div>
        <div class="item" :class="status === 3 ? 'active' : ''" @click="status = 3">处理中</div>
      </div>
      <div v-if="pageType == 2" class="status-item">
        <div class="item" :class="zxType === 2 ? 'active' : ''" @click="zxType = 2">定时执行</div>
        <div class="item" :class="zxType === 3 ? 'active' : ''" @click="zxType = 3">结束后执行</div>
        <div class="item" :class="zxType === 4 ? 'active' : ''" @click="zxType = 4">每天执行</div>
      </div>
    </div>
    <div class="list-item">
      <van-list v-model="loading" :finished="finish" @load="getOrderList" loading-text="加载中..." finished-text="" error-text="加载失败">
        <div v-if="finish && !loading && orderList.length == 0" class="empty-item">
          <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
        </div>
        <div v-else class="order-list">
          <router-link class="order-item" v-for="(item, index) in orderList" :key="index" :to="{path: '/orderDetail', query: {id: item.Id}}">
            <div class="order-header">
              <div class="order-no">
                <span>订单ID：{{ item.Id }}</span> <img @click.prevent="handleCopy(`${item.Id}`)" src="../../assets/images/copy-icon.png" />
              </div>
              <div class="status" :class="'status-' + item.OrderStatus">{{ orderStatusObj[item.OrderStatus] }}</div>
            </div>
            <div class="goods-info">
              <div class="img img-contain">
                <img v-if="item.Img" :src="item.Img" />
                <img v-else src="../../assets/images/no-image-icon.png" />
              </div>
              <div class="info">
                <router-link :to="{ path: '/goodsDetail', query: {id: item.GoodsId} }" class="name">{{ item.GoodsName }}</router-link>
                <div class="detail">
                  <div class="money">¥ {{ Math.round(item.SellingPrice * 100000) / 100000 }}</div>
                  <div class="count">x {{ item.BuyNumber }}</div>
                </div>
              </div>
            </div>
            <div v-if="item.BuyParams && item.BuyParams.length > 0" class="params-item">
              <div v-for="(params, paramsindex) in item.BuyParams" :key="paramsindex" class="params">
                <div class="value">{{ params.name }}: {{ params.value }}</div>
                <img @click.prevent="handleCopy(params.value)" src="../../assets/images/copy-icon.png" />
              </div>
            </div>
            <div class="count-item">
              <div class="item">开始 {{ item.StartNum }}</div>·
              <div class="item">当前 {{ item.CurrentNum }}</div>·
              <div class="item">完成 {{ item.CurrentNum - item.StartNum }}</div>
            </div>
            <div class="money-item">实付款 <span>¥ {{ Math.round(item.Amount * 100000) / 100000 }}</span></div>
            <div class="footer-item">
              <div v-if="pageType == 1" class="detail">订单详情 <img src="../../assets/images/right-blue-icon.png" /></div>
              <template v-else>
                <div v-if="item.ZxType == 2" class="zx-time">{{ $TOOL.dateFormat(item.ExpTime * 1000) }}执行</div>
                <div v-if="item.ZxType == 3" class="zx-time">订单{{ item.ZxOrderId }}结束后延迟{{ item.ExeTime }}分钟后执行</div>
                <div v-if="item.ZxType == 4" class="zx-time">每天{{ $TOOL.dateFormat(item.ExpTime * 1000, 'hh:mm') }}执行</div>
              </template>
              <div class="flex-1"></div>
              <div class="btn" v-if="item.CanTui.includes(`${item.OrderStatus}`) && item.OrderStatus != 4 && item.OrderStatus != 5 && item.OrderStatus != 6 && item.OrderStatus != 7" @click.prevent="handleAppRefund(item, index)">申请退单</div>
              <router-link :to="{ path: '/goodsDetail', query: {id: item.GoodsId} }" class="btn blue">再次下单</router-link>
              <!-- <div class="btn blue" v-if="item.CanRepeat == 1" @click.prevent="handleReOrder(item, index)">再次下单</div> -->
            </div>
          </router-link>
        </div>
      </van-list>
    </div>
    <van-popup v-model="refundData.show" position="center" style="border-radius: .16rem;">
      <div class="mobile-refund-order-container">
        <div class="title">申请退单</div>
        <div class="detail">确定要申请退单吗？</div>
        <div class="btn-item">
          <div class="btn" @click="refundData.show = false">取消</div>
          <div class="btn sure" @click="handleRefundSubmit">
            <van-loading v-if="refundData.submiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </van-popup>
    <mobileSubmitOrder ref="mobileSubmitOrderDialog" @success="reorderSuccess" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileSubmitOrder from '../../components/mobileSubmitOrder.vue'

export default {
  name: "Order",
  components: {
    mobileSubmitOrder
  },
  data() {
    return {
      Id: '',
      classList: [],
      orderList: [],
      pageCount: 10,
      page: 1,
      finish: false,
      loading: false,
      pageType: 1,
      status: 0,
      zxType: 2,
      orderSN: '',
      searchKey: '',
      orderStatusObj: { '-1': '待付款', '1': '已付款', '2': '处理中', '3': '异常', '4': '已完成', '5': '退单中', '6': '已退单', '7': '已退款', '8': '待处理' },
      refundData: {
        show: false,
        submiting: false,
        data: {},
        index: 0
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    pageType(val) {
      console.log(val)
      this.handleResetOrderList()
    },
    status(val) {
      console.log(val)
      this.handleResetOrderList()
    },
    zxType(val) {
      console.log(val)
      this.handleResetOrderList()
    },
    searchKey(val) {
      if (!val) {
        this.orderSN = ''
        this.handleResetOrderList()
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.Id = this.$route.query.id
    }
  },
  methods: {
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    handleSearch() {
      this.orderSN = this.searchKey
      this.handleResetOrderList()
    },
    handleResetOrderList() {
      this.page = 1
      this.finish = false
      this.loading = false
      this.orderList = []
    },
    async getOrderList() {
      const { pageCount, page, orderList, pageType, orderSN, zxType, status, Id } = this
      const params = {
        PageCount: pageCount, 
        Page: page,
        Id: Id
      }
      if (pageType == 1) {
        if (status) {
          params.Status = status
        }
      }
      if (pageType == 2) {
        params.ZxType = zxType
      }
      if (orderSN) {
        params.OrderSN = orderSN
      }
      var res = await this.$API.orderList.post(params)
      const data = []
      for (let index = 0; index < res.info.length; index++) {
        const item = res.info[index]
        data.push({
          ...item,
          CanTui: item.CanTui.split(','),
          BuyParams: JSON.parse(item.BuyParams)
        })
      }
      this.orderList = [
        ...orderList,
        ...data
      ]
      this.page = page + 1
      this.finish = data.length < pageCount
      this.loading = false
    },
    async handleAppRefund(item, index) {
      this.refundData = {
        show: true,
        submiting: false,
        data: {
          ...item
        },
        index: index
      }
    },
    async handleRefundSubmit() {
      const { refundData } = this
      this.refundData.submiting = true
      var res = await this.$API.appRefund.post({
          Id: refundData.data.Id
        })
        this.refundData.submiting = false
        this.refundData.show = false
        if (res.error == 0) {
          this.$toast.success(res.info)
          this.orderList[refundData.index].OrderStatus = 5
        } else {
          this.$dialog.alert({
            type: 'danger',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
    },
    async handleReOrder(order) {
      const orderParams = []
      for (let index = 0; index < order.BuyParams.length; index++) {
        const item = order.BuyParams[index];
        orderParams.push({
          name: item.name,
          alias: item.key,
          value: item.value
        })
      }
      const params = {
        GoodsIds: order.GoodsId,
        ZxType: 1,
        OrderNum: order.BuyNumber,
        OrderParams: JSON.stringify([orderParams]),
        ExpTime: this.$TOOL.dateFormat(new Date())
      }
      this.$refs.mobileSubmitOrderDialog.open({
        params,
        money: order.Amount
      })
    },
    reorderSuccess() {
      this.handleResetOrderList()
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
}
.page-header {
  position: relative;
  padding-top: .24rem;
  height: 2.74rem;
  background-color: #fff;
  border-radius: 0 0 .24rem .24rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .back-item {
    position: absolute;
    left: .14rem;
    top: .24rem;
    width: .6rem;
    height: .6rem;
  }
  .page-type-item {
    padding: .08rem .12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.96rem;
    height: .56rem;
    background-color: #f7f7f7;
    border-radius: .36rem;
    .item {
      flex: 1;
      height: .56rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #242941;
      font-size: .28rem;
      font-weight: 500;
      border-radius: .28rem;
      &.active {
        color: #fff;
        background: linear-gradient( 135deg, #58b7ff 0%, #1399ff 100%);
      }
    }
  }
  .search-item {
    width: 6.58rem;
    height: .8rem;
    margin-top: .32rem;
    border-radius: .4rem;
    background-color: #f7f7f7;
    padding: 0 .08rem 0 .2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      width: .32rem;
      height: .32rem;
    }
    .input {
      flex: 1;
      height: .56rem;
      padding: 0 .2rem;
    }
    .btn {
      width: 1.14rem;
      height: .56rem;
      color: #fff;
      font-size: .24rem;
      border-radius: .28rem;
      background: linear-gradient( 135deg, #58b7ff 0%, #1399ff 100%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .status-item {
    width: 100%;
    padding-top: .24rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .item {
      height: .42rem;
      line-height: .42rem;
      padding-bottom: .24rem;
      position: relative;
      font-weight: bold;
      color: #b3b3b3;
      font-size: .28rem;
      &.active {
        color: #151d2b;
        font-size: .3rem;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: .6rem;
          height: .08rem;
          background-color: #1399ff;
        }
      }
    }
  }
}
.list-item {
  padding: .32rem;
  flex: 1;
  overflow-y: auto;
  .order-item {
    display: block;
    background-color: #fff;
    border-radius: .12rem;
    padding: 0 .24rem .24rem;
    margin-bottom: .24rem;
    .order-header {
      width: 100%;
      height: .82rem;
      border-bottom: .02rem solid #f7f7f7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .order-no {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #b3b3b3;
        font-size: .24rem;
        img {
          width: .24rem;
          height: .24rem;
          margin-left: .2rem;
        }
      }
      .status {
        color: #ffa058;
        font-size: .28rem;
        font-weight: 500;
        &.status-4 {
          color: #1399ff;
        }
      }
    }
    .goods-info {
      margin-top: .24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img {
        width: 1.08rem;
        height: 1.08rem;
        border-radius: .12rem;
        overflow: hidden;
      }
      .info {
        width: 5.14rem;
        height: 1.08rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        color: #151d2b;
        .name {
          color: #151d2b;
          width: 100%;
          line-height: .4rem;
          font-size: .28rem;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .detail {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .money {
            font-size: .32rem;
          }
          .count {
            font-size: .24rem;
            color: #ccc;
          }
        }
      }
    }
    .params-item {
      margin-top: .2rem;
      .params {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .value {
          max-width: 5.9rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: .34rem;
          color: #ccc;
          font-size: .24rem;
        }
        img {
          width: .24rem;
          height: .24rem;
          margin-left: .2rem;
        }
      }
    }
    .count-item {
      margin-top: .24rem;
      width: 6.38rem;
      height: .48rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #151d2b;
      background-color: #f7f7f7;
      border-radius: .08rem;
      .item {
        font-size: .22rem;
        margin: 0 .06rem;
      }
    }
    .money-item {
      margin-top: .24rem;
      color: #151d2b;
      font-size: .24rem;
      span {
        margin-left: .12rem;
        color: #ff2442;
        font-size: .32rem;
        font-weight: bold;
      }
    }
    .footer-item {
      margin-top: .24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .detail {
        margin-left: -.24rem;
        width: 1.62rem;
        height: .48rem;
        border-radius: 0 .24rem .24rem 0;
        background-color: rgba(19, 153, 255, .16);
        color: #1399ff;
        font-size: .24rem;
        font-weight: 500;
        padding-left: .04rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: .2rem;
          height: .2rem;
          margin-left: .04rem;
        }
      }
      .zx-time {
        color: #ffa058;
        font-size: .24rem;
      }
      .btn {
        width: 1.6rem;
        height: .64rem;
        border-radius: .12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: .28rem;
        font-weight: 500;
        background-color: #ccc;
        margin-left: .2rem;
        &.blue {
          background-color: #1399ff;
        }
      }
    }
  }
}
.mobile-refund-order-container {
  width: 5rem;
  padding: .4rem;
  text-align: center;
  .title {
    line-height: .48rem;
    color: #11113a;
    font-size: .32rem;
    font-weight: bold;
  }
  .detail {
    margin-top: .28rem;
    color: #bcbdc5;
    line-height: .4rem;
    font-size: .28rem;
  }
  .btn-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    .btn {
      width: 2.4rem;
      height: .8rem;
      border-radius: .12rem;
      background-color: #bac0cc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: .24rem;
      &.sure {
        background-color: #1399ff;
      }
    }
  }
}
</style>